import { useNavigate } from 'react-router-dom';
import { Box, Dialog } from '@mui/material';

export const EditChargingHub = () => {
  const navigate = useNavigate();
  return (
    <Dialog
      onClose={() => navigate(-1)}
      open={true}
      maxWidth="lg"
      fullWidth={true}
    >
      <Box p={5}>
        <h1>Edit charging hub placeholder</h1>
      </Box>
    </Dialog>
  );
};
