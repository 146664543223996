import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddChargingHubFormData } from './AddChargingHubForm';

const locationOptions: AddChargingHubFormData['locations'] = [
  'roof',
  'foodService',
  'seating',
  'sanitation',
];

const vehicleSupplies: AddChargingHubFormData['vehicleSupplies'] = [
  'airPressure',
  'windshieldWiperSystem',
  'vacuumCleaner',
];

const sustainability: AddChargingHubFormData['sustainability'] = [
  'greenRoof',
  'photovoltaicSystem',
];

export const AddChargingHubAuxiliaryFacilitiesForm = () => {
  const [t] = useTranslation('chargingHubs');
  const { control } = useFormContext();

  return (
    <>
      <Box mb={6}>
        <Typography mb={2} fontSize={12}>
          {t('location')}
        </Typography>
        <Stack flexDirection="row">
          {locationOptions.map((option) => {
            return (
              <Controller
                key={option}
                control={control}
                render={({ field: { onChange, value: locations } }) => {
                  return (
                    <AuxiliaryCheckBox
                      option={option}
                      onChange={onChange}
                      auxilliaryFacilities={locations}
                      checked={locations.includes(option)}
                    />
                  );
                }}
                name="locations"
              />
            );
          })}
        </Stack>
      </Box>
      <Stack flexDirection="row">
        <Box mr={12}>
          <Typography mb={2} fontSize={12}>
            {t('vehicleSupplies')}
          </Typography>
          <Stack flexDirection="row">
            {vehicleSupplies.map((option) => {
              return (
                <Controller
                  key={option}
                  control={control}
                  render={({ field: { onChange, value: vehicleSupplies } }) => {
                    return (
                      <AuxiliaryCheckBox
                        option={option}
                        onChange={onChange}
                        auxilliaryFacilities={vehicleSupplies}
                        checked={vehicleSupplies.includes(option)}
                      />
                    );
                  }}
                  name="vehicleSupplies"
                />
              );
            })}
          </Stack>
        </Box>
        <Box>
          <Typography mb={2} fontSize={12}>
            {t('sustainability')}
          </Typography>
          <Stack flexDirection="row">
            {sustainability.map((option) => {
              return (
                <Controller
                  key={option}
                  control={control}
                  render={({ field: { onChange, value: sustainability } }) => {
                    return (
                      <AuxiliaryCheckBox
                        option={option}
                        onChange={onChange}
                        auxilliaryFacilities={sustainability}
                        checked={sustainability.includes(option)}
                      />
                    );
                  }}
                  name="sustainability"
                />
              );
            })}
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

interface AuxiliaryCheckBoxProps {
  option: string;
  auxilliaryFacilities: string[];
  onChange: (array: string[]) => void;
  checked: true;
}

const AuxiliaryCheckBox: React.FC<AuxiliaryCheckBoxProps> = ({
  option,
  auxilliaryFacilities,
  onChange,
  checked,
}) => {
  const [t] = useTranslation('chargingHubs');

  return (
    <Box
      sx={{
        backgroundColor: checked ? 'primary.light' : 'transparent',
        pr: 2,
        py: 2,
        pl: 4,
        borderRadius: (theme) => theme.spacing(3),
        mr: 3,
        border: '1px solid',
        borderColor: checked ? 'primary.main' : 'gray.300',
      }}
    >
      <FormControlLabel
        value={option}
        label={t(option)}
        control={
          <Checkbox
            checked={auxilliaryFacilities?.includes(option)}
            onChange={(e) => {
              const newAuxiliaryFacilities = [...auxilliaryFacilities];
              if (e.target.checked) {
                newAuxiliaryFacilities.push(option);
              } else {
                const removedFacility = newAuxiliaryFacilities.findIndex(
                  (formOption: string) => formOption === option
                );
                newAuxiliaryFacilities.splice(removedFacility, 1);
              }
              onChange(newAuxiliaryFacilities);
            }}
          />
        }
      />
    </Box>
  );
};
