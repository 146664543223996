export const ObelisRoutes = {
  ChargingHubs: '/charging-hubs',
  AddChargingHub: 'add',
  ChargingHubDetailsGeneralTab: 'general',
  ChargingHubDetailsChargingStationsTab: 'charging-stations',
  ChargingHubDetailsReportsTab: 'reports',
  ChargingHubDetails: ':id',
  EditChargingHubFromTable: ':name/:id/edit',
  EditChargingHub: 'edit',
  ChargingStations: '/charging-stations',
};
