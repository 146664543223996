export const states = [
  'BB',
  'BE',
  'BW',
  'BW',
  'BY',
  'HB',
  'HE',
  'MV',
  'NI',
  'NW',
  'RP',
  'SH',
  'SL',
  'SN',
  'ST',
  'TH',
] as const;
export type StateDto = (typeof states)[number];
