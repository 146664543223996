import { Grid } from '@mui/material';
import { ChargingHubDetailsGeneralCard } from './ChargingHubDetailsGeneralCard';
import { ChargingHubDetailsMap } from './ChargingHubDetailsMap';

export const ChargingHubDetailsGeneral = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={5} xl={4}>
        <ChargingHubDetailsGeneralCard />
      </Grid>
      <Grid item xs={12} lg={7} xl={8}>
        <ChargingHubDetailsMap />
      </Grid>
    </Grid>
  );
};
