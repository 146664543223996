import { AuxiliaryFacilityModel } from '@energy-stacks/obelis/feature-charging-hubs-data';

export const handleAuxiliaryFacilityCheckboxChange = (
  checked: boolean,
  facilities: AuxiliaryFacilityModel[],
  option: AuxiliaryFacilityModel,
  onChange: (array: AuxiliaryFacilityModel[]) => void
) => {
  const newAuxiliaryFacilities = [...facilities];
  if (checked) {
    newAuxiliaryFacilities.push(option);
  } else {
    const removedFacilityIndex = newAuxiliaryFacilities.findIndex(
      (formOption: string) => formOption === option
    );
    newAuxiliaryFacilities.splice(removedFacilityIndex, 1);
  }
  onChange(newAuxiliaryFacilities);
};
