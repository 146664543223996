import { OnChangeFn, SortingState } from '@tanstack/react-table';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

export type Sort = 'ASC' | 'DESC';

export interface SortingOrderModel {
  order: Sort;
}

export interface BackendSortingOrderModel {
  id: string;
  order: Sort;
}

export const DEFAULT_SORT_CONFIG: SortingOrderModel = {
  order: 'DESC',
};

export const useSort = (
  serverSortColumn?: string,
  initialSortingState?: SortingState,
  shouldPersist?: boolean
): {
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  sortOrder: Sort;
  sortColumn: string | undefined;
} => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getSortingParamValue = useCallback(() => {
    const sortingUrlParams: SortingState = [];
    if (shouldPersist) {
      for (const [name, value] of searchParams.entries()) {
        if (value === 'ASC' || value === 'DESC') {
          sortingUrlParams.push({
            id: name.replace('Sort', ''),
            desc: value === 'DESC' ? true : false,
          });
          // There will always be only one sorting param
          break;
        }
      }
    }

    return sortingUrlParams;
  }, [searchParams, shouldPersist]);
  const [sorting, setSorting] = useState<SortingState>(() => {
    if (searchParams) {
      // persistedSorting has preference over initial(default) sorting
      // since it's applied later in tables lifecycle
      return getSortingParamValue();
    }
    return initialSortingState || [];
  });

  const sortOrder = useMemo(() => {
    if (sorting.length) {
      return sorting[0].desc ? 'DESC' : 'ASC';
    }

    return DEFAULT_SORT_CONFIG.order;
  }, [sorting]);

  const handleSorting: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const newSorting =
        typeof updaterOrValue === 'function'
          ? updaterOrValue(sorting)
          : updaterOrValue;

      if (shouldPersist) {
        setSearchParams(
          (params) => {
            const [sortingParam] = getSortingParamValue();

            if (sortingParam) {
              params.delete(`${sortingParam.id}Sort`);
            }

            newSorting.forEach((sort) => {
              params.set(`${sort.id}Sort`, sort.desc ? 'DESC' : 'ASC');
            });
            return params;
          },
          { replace: true }
        );
      }
      return setSorting(updaterOrValue);
    },
    [getSortingParamValue, setSearchParams, shouldPersist, sorting]
  );

  const sortColumn = useMemo(() => {
    if (sorting.length) {
      return sorting[0].id;
    }

    return serverSortColumn;
  }, [sorting, serverSortColumn]);
  return { sorting, setSorting: handleSorting, sortOrder, sortColumn };
};
