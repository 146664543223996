import { ChargingHubModel } from '../chargingHubModel';
import { ChargingHubDto } from '../chargingHubsDto';

export const chargingHubsNormalizer = (
  chargingHub: ChargingHubDto
): ChargingHubModel => {
  return {
    id: chargingHub.id,
    address: chargingHub.address,
    chargingStations: chargingHub.chargingStations,
    city: chargingHub.city,
    coordinates: chargingHub.coordinates,
    lastModifiedDate: chargingHub.lastModifiedDate,
    name: chargingHub.name,
  };
};
