import { IconButton, Menu } from '@mui/material';
import { IconDotsVertical } from '@tabler/icons-react';
import React, { createContext, useEffect } from 'react';
import { usePopover } from '../usePopover';
import { grey } from '@mui/material/colors';

interface ESMenuProps {
  children: React.ReactNode;
  disableMenu?: boolean;
  testId?: string;
  iconColor?: string;
}

export const ESMenuContext = createContext<
  { closeESMenu: () => void } | undefined
>(undefined);

export const ESMenu: React.FC<ESMenuProps> = ({
  children,
  disableMenu = false,
  testId,
  iconColor,
}) => {
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();

  useEffect(() => {
    if (disableMenu) {
      closePopover();
    }
  }, [closePopover, disableMenu]);

  return (
    <ESMenuContext.Provider value={{ closeESMenu: closePopover }}>
      {/* Size small in design is of height 36 and default MUI size small is of hight 34. Add 1px spacing to to fix MUI inconsistency between Button and IconButton */}
      <IconButton
        data-testid={`${testId}ActionMenu`}
        size="small"
        onClick={openPopover}
        disabled={disableMenu}
      >
        <IconDotsVertical color={iconColor ? iconColor : grey[500]} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={popoverOpen}
        keepMounted
        onClose={(event: Event, reason) => {
          if (reason === 'backdropClick') {
            event.stopPropagation();
          }

          closePopover();
        }}
        sx={{ '.MuiMenu-list': { padding: 0 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        {children}
      </Menu>
    </ESMenuContext.Provider>
  );
};
