import { ESMenu } from '@energy-stacks/core/ui';
import React from 'react';

interface MoreOptionsMenuProps {
  children: React.ReactNode;
  disableMenu?: boolean;
  testId?: string;
}

export const MoreOptionsMenu: React.FC<MoreOptionsMenuProps> = ({
  children,
  disableMenu = false,
  testId,
}) => {
  return (
    <ESMenu testId={testId} disableMenu={disableMenu}>
      {children}
    </ESMenu>
  );
};
