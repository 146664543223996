import {
  addMinutes,
  endOfDay,
  format,
  formatDistanceToNowStrict,
  parseISO,
  startOfDay,
} from 'date-fns';
import { enUS, de } from 'date-fns/locale';
import i18next from 'i18next';

const locales = { en: enUS, de };

const ES_DATE_FORMAT = 'dd MMM yyyy';
const ES_DATE_TIME_FORMAT = 'dd MMM yyyy HH:mm:ss';
const ES_DATE_TIME_FORMAT_BE = "yyyy-MM-dd'T'HH:mm:ss";
const ES_DATE_TIME_FORMAT_BE_BROKER = "yyyy-MM-dd'T'HH:mm:ss'Z'";

function findLocale(
  locales: Record<string, Locale>,
  language = 'en'
): Locale | undefined {
  if (typeof locales === 'undefined') {
    return undefined;
  }

  const _index = language.indexOf('.');

  if (_index > -1) {
    return findLocale(locales, language.substring(_index + 1));
  }

  return locales[language];
}

export const formatDate = (date: string, dateFormat = ES_DATE_FORMAT) =>
  format(new Date(date), dateFormat, {
    locale: findLocale(locales, i18next.language),
  });

export const formatDateTime = (
  date: string,
  dateTimeFormat = ES_DATE_TIME_FORMAT
) =>
  format(new Date(date), dateTimeFormat, {
    locale: findLocale(locales, i18next.language),
  });

export const toPayloadDate = (
  date: Date,
  dateFormat: string = ES_DATE_TIME_FORMAT_BE
) => format(addMinutes(date, date.getTimezoneOffset()), dateFormat);

export const toPayloadDateBroker = (
  date: Date,
  dateFormat: string = ES_DATE_TIME_FORMAT_BE_BROKER
) => format(addMinutes(date, date.getTimezoneOffset()), dateFormat);

export const toPayloadDateFullDay = (datestring: string) => {
  return {
    startOfDay: format(
      startOfDay(parseISO(datestring)),
      ES_DATE_TIME_FORMAT_BE
    ),
    endOfDay: format(endOfDay(parseISO(datestring)), ES_DATE_TIME_FORMAT_BE),
  };
};

export const timeAgoFromDate = (date: string | null) => {
  if (date && date !== 'N/A') {
    return formatDistanceToNowStrict(new Date(date), {
      addSuffix: true,
      locale: findLocale(locales, i18next.language),
    });
  }

  return 'N/A';
};
export const appendZToDateString = (
  date: string | null | undefined
): string => {
  if (!date) return '';

  return date.includes('Z') ? date : `${date}Z`;
};

export const formatDateLong = (date: Date) => {
  // This format works, but this should be formatISO piped date
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
};
