import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormStepItemModel,
  FormStepper,
  FormStepperAction,
  translateFormSteps,
} from '@energy-stacks/form-stepper';
import { ESDialogContent, showSnackbar } from '@energy-stacks/core/ui';
import { Box, useMediaQuery } from '@mui/material';
import {
  EditChargingHubFormStepName,
  editChargingHubSteps,
} from './editChargingHubSteps';
import { editChargingHubValidationSchema } from './editChargingHubValidationSchema';
import { EditChargingHubGeneralDetailsForm } from './EditChargingHubGeneralDetailsForm';
import { EditChargingHubAuxiliaryFacilitiesForm } from './EditChargingHubAuxiliaryFacilitiesForm';
import {
  AuxiliaryFacilityModel,
  StateDto,
  auxiliaryFacilitiesModelToDtoMap,
  chargingHubsApiErrors,
  useEditChargingHubMutation,
} from '@energy-stacks/obelis/feature-charging-hubs-data';

const EDIT_CHARGING_HUB_DIALOG_CONTENT_HEIGHT = 500;

interface EditChargingHubFormProps {
  display: EditChargingHubFormStepName;
  activeStep: number;
  onFormDirty: (isDirty: boolean) => void;
  onSetIsDialogOpen: (value: boolean) => void;
  defaultValues: EditChargingHubFormData | undefined;
}

export interface EditChargingHubFormData {
  id: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  state: {
    label: string;
    value: StateDto;
  };
  coordinates: {
    latitude: string;
    longitude: string;
  };
  auxiliaryFacilities: AuxiliaryFacilityModel[];
}

export const EditChargingHubForm: React.FC<EditChargingHubFormProps> = ({
  display,
  activeStep,
  onFormDirty,
  onSetIsDialogOpen,
  defaultValues,
}) => {
  const navigate = useNavigate();
  const [isMapReady, setIsMapReady] = useState(false);
  const isMobile = useMediaQuery('(max-width: 699px)');
  const [editChargingHub, { isLoading }] = useEditChargingHubMutation();

  const methods = useForm<EditChargingHubFormData>({
    defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(editChargingHubValidationSchema[display]),
  });

  const {
    formState: { isDirty: isFormDirty, isValid },
    handleSubmit,
    reset: resetForm,
  } = methods;

  useEffect(() => {
    onFormDirty(isFormDirty);
  }, [onFormDirty, isFormDirty]);

  const handleClose = useCallback(() => {
    onSetIsDialogOpen(false);
    navigate(-1);
    resetForm();
  }, [navigate, onSetIsDialogOpen, resetForm]);

  const editChargingHubForms: FormStepItemModel<EditChargingHubFormStepName> =
    useMemo(
      () => ({
        generalDetails: (
          <EditChargingHubGeneralDetailsForm
            defaultValues={defaultValues}
            onSetIsMapReady={setIsMapReady}
          />
        ),
        auxiliaryFacilities: <EditChargingHubAuxiliaryFacilitiesForm />,
      }),
      [defaultValues]
    );

  const onSubmit: SubmitHandler<EditChargingHubFormData> = (data) => {
    const body = {
      name: data.name,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      state: data.state.value,
      coordinates: data.coordinates,
      auxiliaryFacilities: data.auxiliaryFacilities.map(
        (facility) => auxiliaryFacilitiesModelToDtoMap[facility]
      ),
    };

    editChargingHub({ body: body, id: data.id })
      .unwrap()
      .then(() => {
        showSnackbar('success', 'editChargingHubSuccess', 'chargingHubs');
        handleClose();
      })
      .catch((error) => {
        showSnackbar(
          'error',
          chargingHubsApiErrors[error.data?.errorCode],
          'chargingHubs'
        );
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        id="edit-charging-hub-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <ESDialogContent
          sx={{
            flexGrow: 1,
          }}
        >
          <FormStepper
            formSteps={translateFormSteps(editChargingHubSteps, 'chargingHubs')}
            activeStep={activeStep}
          />
          <Box
            sx={{
              pt: 10,
              height: isMobile
                ? 'auto'
                : `${EDIT_CHARGING_HUB_DIALOG_CONTENT_HEIGHT}px`,
            }}
          >
            {editChargingHubForms[display]}
          </Box>
          <Box sx={{ mt: 10, pb: 6 }}>
            <FormStepperAction
              activeStep={activeStep}
              formSteps={editChargingHubSteps}
              disabled={!isValid || !isMapReady || isLoading || !isFormDirty}
              submitFormId="edit-charging-hub-form"
            />
          </Box>
        </ESDialogContent>
      </form>
    </FormProvider>
  );
};
