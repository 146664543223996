import { chargingHubsApi } from '@energy-stacks/obelis/feature-charging-hubs-data';
import {
  SIDEBAR_FEATURE_KEY,
  STEPPER_FEATURE_KEY,
  THEME_FEATURE_KEY,
  USER_FEATURE_KEY,
  sidebarReducer,
  stepperReducer,
  themeReducer,
  userReducer,
} from '@energy-stacks/store';
import {
  AnyAction,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';

const persistConfig = {
  key: 'OBELIS',
  storage,
  // Must keep a whitelist value here or
  // the whole store will be persisted
  whitelist: [THEME_FEATURE_KEY],
};

export const rootReducer = combineReducers({
  [THEME_FEATURE_KEY]: themeReducer,
  [USER_FEATURE_KEY]: userReducer,
  [SIDEBAR_FEATURE_KEY]: sidebarReducer,
  [chargingHubsApi.reducerPath]: chargingHubsApi.reducer,
  [STEPPER_FEATURE_KEY]: stepperReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const initStore = (preloadedState = {}) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    // Additional middleware can be passed to this array
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST'],
        },
      }).concat(chargingHubsApi.middleware),
    devTools:
      (typeof process !== 'undefined' &&
        process.env['NODE_ENV'] !== 'production') ||
      !!import.meta,

    // Optional Redux store enhancers
    enhancers: [],
  });
};

export const store = initStore();

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
