import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  AddChargingHubFormStepName,
  addChargingHubSteps,
} from './addChargingHubSteps';
import { yupResolver } from '@hookform/resolvers/yup';
import { addChargingHubValidationSchema } from './addChargingHubValidationSchema';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormStepItemModel,
  FormStepper,
  FormStepperAction,
  translateFormSteps,
} from '@energy-stacks/form-stepper';
import { AddChargingHubGeneralDetailsForm } from './AddChargingHubGeneralDetailsForm';
import { AddChargingHubOverview } from './AddChargingHubOverview';
import { ESDialogContent, showSnackbar } from '@energy-stacks/core/ui';
import { Box, useMediaQuery } from '@mui/material';
import {
  AuxiliaryFacilityModel,
  StateDto,
  auxiliaryFacilitiesModelToDtoMap,
  chargingHubsApiErrors,
  useAddChargingHubMutation,
} from '@energy-stacks/obelis/feature-charging-hubs-data';
import { AddChargingHubAuxiliaryFacilitiesForm } from './AddChargingHubAuxiliaryFacilitiesForm';

const ADD_CHARGING_HUB_DIALOG_CONTENT_HEIGHT = 500;

interface AddChargingHubsFormProps {
  display: AddChargingHubFormStepName;
  activeStep: number;
  onFormDirty: (isDirty: boolean) => void;
  onSetIsDialogOpen: (value: boolean) => void;
}

export interface AddChargingHubFormData {
  id: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  state: {
    value: StateDto;
    label: string;
  };
  coordinates: {
    latitude: string;
    longitude: string;
  };
  locations: AuxiliaryFacilityModel[];
  vehicleSupplies: AuxiliaryFacilityModel[];
  sustainability: AuxiliaryFacilityModel[];
}

const defaultValues: AddChargingHubFormData = {
  id: '',
  name: '',
  address: '',
  postalCode: '',
  city: '',
  state: {
    label: '',
    value: '' as StateDto,
  },
  coordinates: {
    longitude: '',
    latitude: '',
  },
  locations: [],
  vehicleSupplies: [],
  sustainability: [],
};

export const AddChargingHubForm: React.FC<AddChargingHubsFormProps> = ({
  display,
  activeStep,
  onFormDirty,
  onSetIsDialogOpen,
}) => {
  const navigate = useNavigate();
  const [isMapReady, setIsMapReady] = useState(false);
  const [addChargingHub, { isLoading }] = useAddChargingHubMutation();
  const isMobile = useMediaQuery('(max-width: 699px)');

  const methods = useForm<AddChargingHubFormData>({
    defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(addChargingHubValidationSchema[display]),
  });

  const {
    formState: { isDirty: isFormDirty, isValid },
    handleSubmit,
    reset: resetForm,
  } = methods;

  useEffect(() => {
    onFormDirty(isFormDirty);
  }, [onFormDirty, isFormDirty]);

  const handleClose = useCallback(() => {
    onSetIsDialogOpen(false);
    navigate(-1);
    resetForm();
  }, [navigate, onSetIsDialogOpen, resetForm]);

  const addChargingHubForms: FormStepItemModel<AddChargingHubFormStepName> =
    useMemo(
      () => ({
        generalDetails: (
          <AddChargingHubGeneralDetailsForm
            defaultValues={defaultValues}
            onSetIsMapReady={setIsMapReady}
          />
        ),
        addAuxiliaryFacilities: <AddChargingHubAuxiliaryFacilitiesForm />,
        overview: <AddChargingHubOverview />,
      }),
      []
    );

  const onSubmit: SubmitHandler<AddChargingHubFormData> = (data) => {
    const groupedFacilities = data.locations.concat(
      data.sustainability,
      data.vehicleSupplies
    );

    addChargingHub({
      id: data.id,
      name: data.name,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      state: data.state.value,
      coordinates: data.coordinates,
      auxiliaryFacilities: groupedFacilities.map(
        (facility) => auxiliaryFacilitiesModelToDtoMap[facility]
      ),
    })
      .unwrap()
      .then(() => {
        showSnackbar('success', 'addChargingHubSuccess', 'chargingHubs');
        handleClose();
      })
      .catch((error) => {
        showSnackbar(
          'error',
          chargingHubsApiErrors[error.data?.errorCode],
          'chargingHubs'
        );
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        id="add-charging-hub-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <ESDialogContent
          sx={{
            flexGrow: 1,
          }}
        >
          <FormStepper
            formSteps={translateFormSteps(addChargingHubSteps, 'chargingHubs')}
            activeStep={activeStep}
            fullWidth
          />
          <Box
            sx={{
              pt: 10,
              height: isMobile
                ? 'auto'
                : `${ADD_CHARGING_HUB_DIALOG_CONTENT_HEIGHT}px`,
            }}
          >
            {addChargingHubForms[display]}
          </Box>
          <Box sx={{ mt: 10, pb: 6 }}>
            <FormStepperAction
              activeStep={activeStep}
              formSteps={addChargingHubSteps}
              disabled={!isValid || !isMapReady || isLoading}
              submitFormId="add-charging-hub-form"
            />
          </Box>
        </ESDialogContent>
      </form>
    </FormProvider>
  );
};
