import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ChargingHubsModel } from './chargingHubModel';
import { ChargingHubsPageEntry } from './chargingHubsPageEntry';
import { chargingHubsNormalizer } from './normalizers/chargingHubsNormalizer';
import { AuxiliaryFacilityDto } from './auxiliaryFacilityDto';
import { StateDto } from './stateDto';
import { chargingHubDetailsNormalizer } from './normalizers/chargingHubDetailsNormalizer';
import { ChargingHubDetailsDto } from './chargingHubDetailsDto';
import { ChargingHubDetailsModel } from './chargingHubDetailsModel';

export interface GetChargingHubsBody {
  name?: string;
  address?: string;
  id?: string;
  pageNumber: number;
  pageSize: number;
}

interface AddChargingHubFormData {
  id: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  state: StateDto;
  coordinates: {
    latitude: string;
    longitude: string;
  };
  auxiliaryFacilities: AuxiliaryFacilityDto[];
}

export const chargingHubsApi = createApi({
  reducerPath: 'chargingHubsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/charging-hubs`),
  keepUnusedDataFor: 0,
  tagTypes: ['chargingHubs', 'chargingHubDetails'],
  endpoints: (builder) => ({
    getChargingHubs: builder.query<ChargingHubsModel, GetChargingHubsBody>({
      query: (searchParams) => ({
        url: `/search`,
        method: 'GET',
        params: {
          ...searchParams,
          name: searchParams.name,
          address: searchParams.address,
          id: searchParams.id,
        },
      }),
      providesTags: ['chargingHubs'],
      transformResponse: (chargingHubs: ChargingHubsPageEntry) => {
        return {
          totalElements: chargingHubs.totalElements ?? 0,
          totalPages: chargingHubs.totalPages ?? 0,
          chargingHubs: chargingHubs.content?.map((item) => {
            return chargingHubsNormalizer(item);
          }),
        };
      },
    }),
    addChargingHub: builder.mutation<void, AddChargingHubFormData>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['chargingHubs'],
    }),
    deleteChargingHub: builder.mutation<void, string>({
      query: (chargingHubId) => ({
        url: `/${chargingHubId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['chargingHubs'],
    }),
    getChargingHubDetails: builder.query<ChargingHubDetailsModel, string>({
      query: (id) => ({
        url: id,
        method: 'GET',
      }),
      providesTags: ['chargingHubDetails'],
      transformResponse: (response: ChargingHubDetailsDto) => {
        return chargingHubDetailsNormalizer(response);
      },
    }),
  }),
});

export const {
  useGetChargingHubsQuery,
  useAddChargingHubMutation,
  useGetChargingHubDetailsQuery,
  useDeleteChargingHubMutation,
} = chargingHubsApi;
